@charset "utf-8"

@import "../../styles/bulma_legacy/functions.sass"
@import "../../styles/bulma_legacy/mixins.sass"
@import "../../styles/variables.sass"





































































































.panel-result-elem
  padding: ($column-gap * 3) ($column-gap * 2)

  .is-desktop
    display: none

  .is-mobile
    display: flex
    flex-wrap: wrap

  .door-image
    display: flex
    justify-content: center

    .svg-icon
      width: 180px
      height: auto

  .wa-label

    p
      padding-top: .6rem
      font-family: $family-mono-light
      font-size: $size-4
      line-height: 1.2em

+tablet
  .panel-result-elem

    .is-desktop
      display: flex

    .is-mobile
      display: none

    .tile

      &.is-parent
        padding: .75rem .4rem

      &.is-vertical

        & > .tile.is-child:not(:last-child)
          margin-bottom: 1.5rem !important

    .door-image
      display: flex
      justify-content: center

      .svg-icon
        width: 140px

    .wa-label
      display: flex
      flex-direction: column
      justify-content: center
      text-align: right

      p
        font-size: $size-6

    .has-border-bottom
      border-bottom: 2px solid $border

    .has-border-right
      border-right: 2px solid $border

+desktop
  .panel-result-elem

    .tile

      &.is-parent
        padding: .75rem

    .door-image

      .svg-icon
        width: 180px

