@charset "utf-8"

@import "../../styles/bulma_legacy/functions.sass"
@import "../../styles/bulma_legacy/mixins.sass"
@import "../../styles/variables.sass"


























































































































.result-segment
  background-color: $white-ter
  z-index: $z-index-content

  .doors-wrapper

    &>.column
      padding-bottom: 0 // glue tabs together with panel

    .doors
      margin: 0

      .door-tab
        display: flex
        margin: 0 2px -1px 2px
        min-height: 2.8rem
        background-color: $grey-lighter
        border: 1px solid $border
        cursor: pointer
        z-index: $z-index-content + 1

        &:first-child
          margin-left: 0

        &:last-child
          margin-right: 0

        &.is-active
          background-color: $white
          border-bottom: none
          z-index: $z-index-content + 3

        .name
          flex-grow: 2
          margin-left: -.4rem
          padding-right: .2rem
          font-family: $family-mono
          color: $primary
          font-size: $size-3
          line-height: 1em

        .description
          display: none
          vertical-align: top
          font-size: $size-7
          font-family: $family-mono-light
          line-height: 1.25em

        .error
          text-align: right

          .svg-icon
            margin-right: -.4rem

  .panel-wrapper
    margin-bottom: 0

    &>.column
      padding-top: 0 // glue tabs together with panel

    .panel
      margin: 0
      border: 1px solid $border
      background-color: $white
      z-index: $z-index-content + 2

      .head
        margin-bottom: 0

        h2
          margin-bottom: .3rem
          width: 90%
          font-family: $family-sans-serif-medium

        p
          width: 90%
          font-size: $size-5

      .table
        padding: ($column-gap * 2)

        .table-head

          .column
            padding: .4rem 0
            font-size: $size-5
            border-bottom: 2px solid $border

        .table-row

          .column
            padding: .6rem 0
            font-size: $size-5
            border-bottom: 1px solid $border

            &.value
              flex-grow: 2

            &.action
              flex-grow: 1
              text-align: right

            &.is-emph
              font-family: $family-sans-serif-bold

        &:last-child

          .column
            border-bottom: 2px solid $border

  .actions-wrapper

    .right
      text-align: right

    .tke_icon_print,
    .tke_icon_info
      display: none

+tablet

  .result-segment
    padding: .8rem 0 1.6rem 0
    background-color: $white-ter

    .doors-wrapper

      .doors

        .door-tab
          min-height: 3.6rem

          .name
            flex-grow: 1
            padding-right: .4rem
            margin-left: 0
            font-size: $size-2

          .description
            flex-grow: 2
            display: block

          .error
            width: 2.4rem

+desktop

  .result-segment

    .actions-wrapper

      .tke_icon_print
        display: none // block

