@charset "utf-8"

@import "../../styles/bulma_legacy/functions.sass"
@import "../../styles/bulma_legacy/mixins.sass"
@import "../../styles/variables.sass"


































































































































































































































































.inputs-segment
  z-index: $z-index-menu + 1

+tablet

  .inputs-segment
    z-index: $z-index-content

    .input-help
      font-size: $size-6
      line-height: 1rem

    .section-settings
      padding: .8rem 0 1.6rem 0
      background-color: $white
      border-top: 1px solid $border
      border-bottom: 1px solid $border
      overflow: hidden

    .section-inputs
      padding: 1.6rem 0 .8rem 0
      background-color: $white-ter

+desktop

  .inputs-segment

    .input-help
      font-size: $size-5
      line-height: 1.2rem

