@charset "utf-8"

@import "../../styles/bulma_legacy/functions.sass"
@import "../../styles/bulma_legacy/mixins.sass"
@import "../../styles/variables.sass"






























































































.tipps-segment
  position: relative
  padding: 0
  margin: 0
  z-index: $z-index-dialog

  .container
    padding: 0

    .canvas
      position: absolute
      width: 100%
      padding: $content-padding-mobile ($content-padding-mobile * 2)

      .tke_icon_close
        position: absolute
        right: ($content-padding-mobile * 2)

      .content

        h2
          font-size: $size-2
          color: $primary

        ul
          list-style-type: none
          margin-left: 0

          li
            padding: 0 0 .5rem 0
            font-size: $size-5

            &.is-emph
              font-family: $family-sans-serif-bold

            &.has-button
              position: relative
              padding-right: 7rem

              .button
                position: absolute
                right: 0
                top: .5rem
                max-width: 6rem

        &.general
          padding-top: 2rem
          padding-bottom: 2rem
          background-color: $white-ter
          border-top: 2px solid $primary

+tablet
  .tipps-segment

    .container

      .canvas

        .content

          h2
            font-size: $size-1

          ul
            margin-left: 2rem
            list-style-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAGCAYAAADzG6+8AAABG2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iWE1QIENvcmUgNS41LjAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+Gkqr6gAAAYJpQ0NQc1JHQiBJRUM2MTk2Ni0yLjEAACiRdZG7SwNBEIe/RMVXJIIWFhYxRCsjPiBoI5ggKgQJMYJRm+TMQ8jjuEuQYCvYBhREG1+F/gXaCtaCoCiCWFhZK9poOOcSISJmltn59rc7w+4sWEMpJa3XD0I6k9OCU17HQnjR0fhMMw3YcNITUXR1IhDwU9M+7rCY8cZt1qp97l9rXYnpCliahMcVVcsJTwv713KqydvCnUoysiJ8KtyvyQWFb009WuEXkxMV/jJZCwV9YG0XdiR+cfQXK0ktLSwvx5VO5ZWf+5gvscUy83MSneLd6ASZwouDGSbx4WGIMZk9uBlmQFbUyB8s58+SlVxFZpUCGqskSJKjX9S8VI9JjIsek5GiYPb/b1/1+MhwpbrNCw1PhvHWC41bUCoaxuehYZSOoO4RLjLV/OwBjL6LXqxqrn2wb8DZZVWL7sD5JnQ9qBEtUpbqxK3xOLyeQFsYOq6hZanSs599ju8htC5fdQW7e9An5+3L3zthZ9Ld3IS2AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAGklEQVQYlWNkYGDQYmBgCGMgDaxiIlHDiAIAaokBLocRPF0AAAAASUVORK5CYII=')

            li
              padding: 0 0 0 1rem
              font-size: $size-4

+desktop
  .tipps-segment

    .container

      .canvas
        padding: $content-padding-desktop ($content-padding-desktop * 2)

        .content
          padding: 0 ($content-padding-desktop * 2)
