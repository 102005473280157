// Colors

$black:        #262626 // primary color digital black
$grey-darker:  #434343
$grey-dark:    #434343
$grey:         #9D9D9D
$grey-light:   #C4C4C4
$grey-lighter: #E9E9E9
$white-ter:    #F6F6F6
$white:        #FFFFFF // primary color white

// all Tk-Colors
$purple:       #7000BD // primary color interaction
$orange:       #F16B08 // primary color highlight
$yellow:       #F9CA29
$green:        #237C00
$red:          #A80012

// derived-colors

$primary: $purple
$info: $orange
$success: $green
$warning: $yellow
$danger: $red
$light: $white-ter
$dark: $grey-darker

// Invert colors
$orange-invert: findColorInvert($orange)
$yellow-invert: findColorInvert($yellow)
$green-invert: findColorInvert($green)
$purple-invert: findColorInvert($purple)
$red-invert: findColorInvert($red)
$primary-invert: $purple-invert
$info-invert: $orange-invert
$success-invert: $green-invert
$warning-invert: $yellow-invert
$danger-invert: $red-invert
$light-invert: $dark
$dark-invert: $light

// Element colors

$background: $white-ter
$border: $grey-lighter
$border-hover: $grey-light

// Text colors

$text: $black
$text-invert: findColorInvert($text)
$text-light: $grey
$text-strong: $black

// Link colors

$link: $primary
$link-invert: $primary-invert
$link-visited: $primary
$link-hover: #A31AFF
$link-hover-border: #A31AFF
$link-focus: $primary
$link-focus-border: $primary
$link-active: #58008D
$link-active-border: #58008D

// Code colors

$code: $red
$code-background: $background
$pre: $text
$pre-background: $background

// Typography

$family-sans-serif: tketyperegular, Helvetica, Arial, sans-serif
$family-sans-serif-italic: tketypeitalic, Helvetica, Arial, sans-serif
$family-sans-serif-medium: tketypemedium, Helvetica, Arial, sans-serif
$family-sans-serif-bold: tketypebold, Helvetica, Arial, sans-serif
$family-mono-light: tkemonolight, Courier, monospace
$family-mono: tkemonoregular, Courier, monospace

$family-primary: $family-sans-serif
$family-monospace: $family-mono
$render-mode: optimizeLegibility
$family-code: $family-mono

$size-1: 1.7rem
$size-2: 1.5rem
$size-3: 1.3rem
$size-4: 1.2rem
$size-5: 0.9rem
$size-6: 0.8rem
$size-7: 0.65rem

$size-label-small: .75rem

$weight-light: normal
$weight-normal: normal
$weight-medium: normal
$weight-semibold: normal
$weight-bold: normal

// Responsiveness and dimensions
// we only have one breakpoint and therefore two viewports: tablet and desktop

$gap: 0
$tablet: 768px
$desktop: 968px
$widescreen-enabled: false
$fullhd-enabled: false

$content-padding-mobile: 15px
$content-width-desktop: 968px
$content-padding-desktop: 20px
$toolbar-height-mobile: 4.5rem
$column-gap: 0.75rem // bulma value, not a configurable variable

$icon-big: 2.4rem
$icon-medium: 2.2rem
$icon-normal: 1.8rem
$icon-small: 1.1rem
$icon-tiny: .7rem

$icon-big-mobile: 2.4rem // change
$icon-medium-mobile: 2.2rem // change
$icon-normal-mobile: 3rem
$icon-small-mobile: 1.6rem
$icon-tiny-mobile: .7rem // change

// Z-index hell
// always use 10-steps to give components the possibility to order their elements
$z-index-content: 10
$z-index-overlay-dialog: 20
$z-index-dialog: 30
$z-index-header: 40
$z-index-overlay-menu: 50
$z-index-menu: 60

// Miscellaneous

$easing: ease-out
$radius-small: 2px
$radius: 4px
$radius-large: 6px
$radius-rounded: 290486px
$speed: 86ms

// Flags

$variable-columns: true

// Lists and maps
  
$custom-colors: null
$custom-shades: null

$colors: mergeColorMaps(("white": ($white, $black), "black": ($black, $white), "light": ($light, $light-invert), "dark": ($dark, $dark-invert), "primary": ($primary, $primary-invert), "link": ($link, $link-invert), "info": ($info, $info-invert), "success": ($success, $success-invert), "warning": ($warning, $warning-invert), "danger": ($danger, $danger-invert)), $custom-colors)
$shades: mergeColorMaps(("grey-darker": $grey-darker, "grey-dark": $grey-dark, "grey": $grey, "grey-light": $grey-light, "grey-lighter": $grey-lighter, "white-ter": $white-ter), $custom-shades)

$sizes: $size-1 $size-2 $size-3 $size-4 $size-5 $size-6 $size-7