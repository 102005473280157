@charset "utf-8"

@import "../../styles/bulma_legacy/functions.sass"
@import "../../styles/bulma_legacy/mixins.sass"
@import "../../styles/variables.sass"






































.product-select-segment
  display: none
  z-index: $z-index-content

+tablet

  .product-select-segment
    display: block
    padding: .3rem 0 .3rem 0
    background-color: $white

    .container
      display: flex
      a
        padding: .25rem .4rem
        font-size: $size-4
        font-family: $family-sans-serif
        &.router-link-exact-active,
        &:hover
          color: $primary
          &::after
            content: ""
            display: block
            border-top: 1px solid $primary
            left: 0
            right: 0
            bottom: -.2rem
      .settings
        margin-left: auto
+desktop

  .product-select-segment
    .container
      a
        padding: .25rem .8rem

